import React, { Fragment, useEffect, useRef, useState } from "react";
import { Hero, About, How, Tokenomics, Roadmap, Contact } from "../components";
import FloatingButtons from "../components/FloatButtons/FloatBTN";
import halloween from "../musics/Hallowl.mp3";
export default function Main() {
  useEffect(() => {
    const handleClick = () => {
      const audio = new Audio(halloween);
      audio.play().catch((error) => {
        console.error("Error playing audio:", error);
      });
      document.removeEventListener("click", handleClick);
    };

    document.addEventListener("click", handleClick);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  // const audioRef = useRef(null);
  // const [isPlaying, setIsPlaying] = useState(false);

  // useEffect(() => {
  //   // Initialize the audio object
  //   audioRef.current = new Audio(halloween);

  //   const handleClick = () => {
  //     if (audioRef.current) {
  //       if (isPlaying) {
  //         audioRef.current.pause();
  //       } else {
  //         audioRef.current.play().catch((error) => {
  //           console.error("Error playing audio:", error);
  //         });
  //       }
  //       setIsPlaying(!isPlaying);
  //     }
  //   };

  //   document.addEventListener("click", handleClick);

  //   // Cleanup the event listener on component unmount
  //   return () => {
  //     document.removeEventListener("click", handleClick);
  //     if (audioRef.current) {
  //       audioRef.current.pause();
  //     }
  //   };
  // }, [isPlaying]);
  return (
    <Fragment>
      <Hero />
      <About />

      <How />

      <FloatingButtons />
    </Fragment>
  );
}
