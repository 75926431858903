import React, { useState, useEffect } from "react";
import "./Float.css"; // Import your CSS for styling
import { motion } from "framer-motion";
import { heroIcons } from "../../data/constants";

const FloatingButtons = () => {
  const [showButtons, setShowButtons] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setShowButtons(true);
      } else {
        setShowButtons(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className={`floating-buttons ${showButtons ? "show" : ""}`}>
      {heroIcons.map((icon, idx) => {
        return (
          <motion.a
            href={icon.link}
            target="_blank"
            key={icon.title}
            className="h-12 w-12 sm:w-20 sm:h-20 rounded-full bg-[#fff] hover:scale-110  flex items-center justify-center"
            initial={{ opacity: 0, translateY: "-100%" }}
            whileInView={{ opacity: 1, translateY: "0%" }}
            transition={{
              delay: idx * 0.1,
              duration: 0.5,
              type: "spring",
              stiffness: 70,
            }}
            viewport={{ once: true, amount: 0.5 }}
          >
            <motion.img
              // initial={{ opacity: 0, translateY: "-100%" }}
              // whileInView={{ opacity: 1, translateY: "0%" }}
              // transition={{
              //   delay: idx * 0.1,
              //   duration: 0.5,
              //   type: "spring",
              //   stiffness: 70,
              // }}
              // viewport={{ once: true, amount: 0.5 }}
              src={`/images/Hero/${icon.image}.png`}
              alt={icon.title}
              loading="lazy"
              className="cursor-pointer h-8 w-8 sm:w-16 sm:h-16   rounded-full bg-cardBrown"
            />
          </motion.a>
        );
      })}
    </div>
  );
};

export default FloatingButtons;
