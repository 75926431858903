import React, { Fragment } from "react";

export default function Footer() {
  return (
    <Fragment>
      <div className=" pt-10 pb-20 ">
        <p className="text-center text-white font-bold text-[13px] ">
          ©{new Date().getFullYear()} by Halloween Owl.
        </p>
        <p className="text-center text-white font-bold text-[13px] ">
          All rights reserved on the Blockchain
        </p>
      </div>
    </Fragment>
  );
}
