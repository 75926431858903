import React from "react";
import "./navbtn.css"; // Import your CSS for styling

const ButtonWithOverlay = ({ text, icon, overlayText, onClick }) => {
  return (
    <div
      className="button-wrapper"
      onClick={onClick}
    >
      <button className="button-with-overlay">
        <span className="button-text">{text}</span>
        {icon && <span className="button-icon">{icon}</span>}
      </button>
      {overlayText && <div className="overlay-text">{overlayText}</div>}
    </div>
  );
};

export default ButtonWithOverlay;

export const ButtonWithOverlaySM = ({ text, icon, overlayText, onClick }) => {
  return (
    <div
      className="button-wrapper"
      onClick={onClick}
    >
      <button className="button-with-overlay-sm">
        <span className="button-text">{text}</span>
        {icon && <span className="button-icon">{icon}</span>}
      </button>
      {overlayText && <div className="overlay-text">{overlayText}</div>}
    </div>
  );
};
