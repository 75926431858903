import React, { Fragment } from "react";
import { howToBuy } from "../../data/constants";
import { motion } from "framer-motion";
import NonDownloadableImage from "../NoDownload/NoDownload";
import { NewContainer } from "../Container";

export default function How() {
  return (
    <Fragment>
      <NewContainer id="Roadmap">
        <motion.h1
          className="font-Comfortaa  text-white font-bold lg:w-[30%] w-[80%] mx-auto text-[45px] sm:py-[30px] text-center"
          initial={{ opacity: 0, translateY: "100%" }}
          whileInView={{ opacity: 1, translateY: "0%" }}
          transition={{
            delay: 0.1,
            duration: 0.5,
            type: "spring",
            stiffness: 60,
          }}
          viewport={{ once: true, amount: 0.5 }}
        >
          ROADMAP
        </motion.h1>
        <div className="w-[95%] sm:w-[100%] mx-auto gap-5 flex flex-col">
          <NonDownloadableImage
            src="/images/hallow/roadmap.png"
            alt="herogif"
          />
          {/* <div className="  bg-cardBrown flex sm:flex-row flex-col items-center gap-10 justify-center py-7 rounded-3xl rounded-tl-none rounded-br-none border-2 border-darkYellow shadow-transparent  shadow-box">
            <div className="flex flex-col text-white py-6 px-2 text gap-3 w-[90%]">
              <motion.h1
                className="font-inter font-bold text-left text-[16px]  md:text-xl"
                initial={{ opacity: 0, translateY: "200%" }}
                whileInView={{ opacity: 1, translateY: "0%" }}
                transition={{
                  delay: 0.1,
                  duration: 0.5,
                  type: "spring",
                  stiffness: 100,
                }}
                viewport={{ once: true, amount: 0.5 }}
              >
                In the depths of the internet, a phenomenon was born.
                Geckofroggy, a charismatic female Gecko Frog, was destined to
                become the face of a revolution. Her journey began in a humble
                shelter, where a volunteer bestowed upon her the name
                Geckofroggy, inspired by her round face resembling the fruit.
              </motion.h1>
              <motion.p
                className="font-Comfortaa text-[13px]  md:text-[16px]"
                initial={{ opacity: 0, translateY: "100%" }}
                whileInView={{ opacity: 1, translateY: "0%" }}
                transition={{
                  delay: 0.1,
                  duration: 0.5,
                  type: "spring",
                  stiffness: 60,
                }}
                viewport={{ once: true, amount: 0.5 }}
              >
                As fate would have it, Geckofroggy's adorable features and
                endearing personality captured the hearts of many. She became
                the prototype for PEPE, a cryptocurrency meme that swept the
                nation. Her image was plastered on trading cards, memes, and
                even tattoos.
              </motion.p>
            </div>
          </div> */}
        </div>
      </NewContainer>
    </Fragment>
  );
}
