import React, { useEffect, useState } from "react";
import "./NonDownloadableImage.css"; // Import your CSS for styling
import { motion } from "framer-motion";

const NonDownloadableImage = ({ src, alt, className }) => {
  useEffect(() => {
    const handleContextMenu = (e) => {
      e.preventDefault();
    };

    document.addEventListener("contextmenu", handleContextMenu);
    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);

  return (
    <div className={`non-downloadable ${className || ""}`}>
      <motion.img
        initial={{ opacity: 0, translateX: "50%" }}
        whileInView={{ opacity: 1, translateX: "0%" }}
        transition={{
          delay: 0.2,
          duration: 0.5,
          type: "spring",
          stiffness: 70,
        }}
        // viewport={{ once: true, amount: 0.5 }}
        src={src}
        alt={alt}
        className=" h-full w-full"
      />

      <div className="overlay"></div>
    </div>
  );
};
export const NonDownloadableImageHero = ({ src, alt, className }) => {
  const [copySuccess, setCopySuccess] = useState("");

  const textToCopy = "EQkKscPYEgQzFaXudeazQByuAi6CazuXw3qV9Ks2ftzH";

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      setCopySuccess("Address copied");
      setTimeout(() => {
        setCopySuccess("");
      }, 2000);
    } catch (err) {
      setCopySuccess("Failed to copy");
      setTimeout(() => {
        setCopySuccess("");
      }, 2000);
    }
  };
  useEffect(() => {
    const handleContextMenu = (e) => {
      e.preventDefault();
    };

    document.addEventListener("contextmenu", handleContextMenu);
    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);

  return (
    <div className={`non-downloadable ${className || "relative"}`}>
      <motion.img
        initial={{ opacity: 0, translateX: "50%" }}
        whileInView={{ opacity: 1, translateX: "0%" }}
        transition={{
          delay: 0.2,
          duration: 0.5,
          type: "spring",
          stiffness: 70,
        }}
        // viewport={{ once: true, amount: 0.5 }}
        src={src}
        alt={alt}
        className=" h-full w-full"
      />

      <div className="overlay ">
        <div className="overlay-content">
          <div className="overlay-text-new flex flex-row justify-center items-center gap-1 sm:gap-2 z-10">
            <div className="break-words break-all whitespace-normal text-xl min-[920px]:text-2xl space-x-6">
              CA:
            </div>
            <button
              onClick={copyToClipboard}
              className={`flex py-[0.125rem] sm:py-1 px-2 border-2 border-white rounded-full text-sm  sm:text-base  hover:scale-105  cursor-pointer bg-[#584290]`}
            >
              Coming soon
            </button>
            {/* <div className="overlay-text-new flex sm:flex-row flex-col justify-center items-center gap-1 sm:gap-2 z-10">
              <div className="break-words break-all whitespace-normal text-[12px] min-[920px]:text-base">
              {copySuccess && <p>{copySuccess}</p>}
              EQkKscPYEgQzFaXudeazQByuAi6CazuXw3qV9Ks2ftzH
            </div>
            <button
              onClick={copyToClipboard}
              className={`flex py-[0.125rem] sm:py-1 px-2 border-2 border-white rounded-full text-sm  sm:text-base  hover:scale-105  cursor-pointer bg-[#584290]`}
            >
              Copy
            </button>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NonDownloadableImage;
