import React, { Fragment } from "react";
import { heroIcons } from "../../data/constants";
import HeroButtons from "./HeroButtons";
import { motion } from "framer-motion";
import Container, { NewContainer } from "../Container";
import NoDownload, { NonDownloadableImageHero } from "../NoDownload/NoDownload";

export default function Hero() {
  return (
    <Fragment>
      {/* <NewContainer id="Home"> */}
      <div
        id="Home"
        className="w-full flex items-center flex-col pt-32 sm:pt-40 justify-center gap-5 "
      >
        <div className=" gap-2 lg:mt-[100px] text-center space-y-3 ">
          <motion.h2
            initial={{ opacity: 0, translateX: "-50%" }}
            whileInView={{ opacity: 1, translateX: "0%" }}
            transition={{
              delay: 0.2,
              duration: 0.5,
              type: "spring",
              stiffness: 60,
            }}
            viewport={{ once: true, amount: 0.5 }}
            className="text-white font-extrabold font-Comfortaa  xs:text-[4rem] text-3xl uppercase leading-10"
          >
            Halloween Owl
          </motion.h2>
          <motion.h3
            initial={{ opacity: 0, translateX: "-50%" }}
            whileInView={{ opacity: 1, translateX: "0%" }}
            transition={{
              delay: 0.2,
              duration: 0.5,
              type: "spring",
              stiffness: 60,
            }}
            viewport={{ once: true, amount: 0.5 }}
            className="text-white font-Comfortaa  xs:text-[32px] text-[20px] font-bold leading-relaxed"
          >
            Africa's Meme Supreme! 🌍🔥
          </motion.h3>
        </div>
        <div className="w-screen">
          <NonDownloadableImageHero
            src="/images/hallow/hero.png"
            alt="herogif"
          />
          {/* <motion.img
              initial={{ opacity: 0, translateX: "50%" }}
              whileInView={{ opacity: 1, translateX: "0%" }}
              transition={{
                delay: 0.2,
                duration: 0.5,
                type: "spring",
                stiffness: 70,
              }}
              viewport={{ once: true, amount: 0.5 }}
              src="/images/hallow/hero.png"
              alt="herogif"
              className="h-full w-full"
            /> */}
        </div>

        {/* <motion.img
          initial={{ opacity: 0, translateY: "-100%" }}
          whileInView={{ opacity: 1, translateY: "0%" }}
          transition={{
            delay: 0.1,
            duration: 0.5,
            type: "spring",
            stiffness: 60,
          }}
          src="/images/Hero/rightpepe.png"
          alt="pepekill"
          loading="lazy"
          className="md:relative absolute right-0 md:top-[-200px] ss:top-[100px] top-[50px] lg:h-[40rem] ss:h-[30rem] h-[20rem] -z-10"
        /> */}
      </div>
      {/* </NewContainer> */}
    </Fragment>
  );
}
