import clsx from "clsx";

const Container = ({ as: Component = "div", className, children }) => {
  return (
    <Component className={clsx("max-w-7xl mx-auto px-6 lg:px-8", className)}>
      <div className="max-w-2xl mx-auto lg:max-w-none">{children}</div>
    </Component>
  );
};

export default Container;

export const NewContainer = ({
  as: Component = "div",
  className,
  children,
  id,
}) => {
  return (
    <Component
      className={clsx(
        "max-w-[1440px] mx-auto px-2 sm:py-6 sm:px-12 ",
        className
      )}
      id={id}
    >
      {children}
    </Component>
  );
};
