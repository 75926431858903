import React, { Fragment } from "react";
import { aboutIcons, heroIcons, howToBuy } from "../../data/constants";
import { motion } from "framer-motion";
import { NewContainer } from "../Container";

export default function How() {
  return (
    <Fragment>
      <NewContainer
        id="About"
        className="my-4"
      >
        <motion.h1
          className="font-Comfortaa  text-white font-bold lg:w-[30%] w-[80%] mx-auto text-[45px] sm:py-[30px] text-center"
          initial={{ opacity: 0, translateY: "100%" }}
          whileInView={{ opacity: 1, translateY: "0%" }}
          transition={{
            delay: 0.1,
            duration: 0.5,
            type: "spring",
            stiffness: 60,
          }}
          viewport={{ once: true, amount: 0.5 }}
        >
          ABOUT
        </motion.h1>
        <div className="w-[95%] sm:w-[100%] mx-auto gap-5 flex flex-col">
          <div className="  bg-black flex sm:flex-row flex-col items-center gap-10 justify-center py-7 rounded-3xl rounded-tl-none rounded-br-none border-2 border-[#F2B00A] shadow-transparent  shadow-box">
            {/* <img
              src={`/images/About/geckof.png`}
              loading="lazy"
              className="h-52"
            /> */}
            <div className="flex flex-col text-white py-6 px-2 text gap-3 w-[90%]">
              <motion.h1
                className="font-inter font-bold text-left text-[16px]  md:text-xl"
                initial={{ opacity: 0, translateY: "200%" }}
                whileInView={{ opacity: 1, translateY: "0%" }}
                transition={{
                  delay: 0.1,
                  duration: 0.5,
                  type: "spring",
                  stiffness: 100,
                }}
                viewport={{ once: true, amount: 0.5 }}
              >
                In the eerie moonlight of the African savanna, a mysterious
                figure emerges with wings spread wide and eyes glowing with
                mischievous delight.
              </motion.h1>
              <motion.p
                className="font-Comfortaa text-[13px]  md:text-[16px]"
                initial={{ opacity: 0, translateY: "100%" }}
                whileInView={{ opacity: 1, translateY: "0%" }}
                transition={{
                  delay: 0.1,
                  duration: 0.5,
                  type: "spring",
                  stiffness: 60,
                }}
                viewport={{ once: true, amount: 0.5 }}
              >
                Meet Halloween Owl, the arbiter of African memes, weaving
                enchanting tales and spreading laughter across the continent.
                With a cackle that echoes through the night, Halloween Owl
                brings you the spookiest and most hilarious memes from every
                corner of Africa.
              </motion.p>
              <motion.p
                className="font-Comfortaa text-[13px]  md:text-[16px]"
                initial={{ opacity: 0, translateY: "100%" }}
                whileInView={{ opacity: 1, translateY: "0%" }}
                transition={{
                  delay: 0.1,
                  duration: 0.5,
                  type: "spring",
                  stiffness: 60,
                }}
                viewport={{ once: true, amount: 0.5 }}
              >
                From the legendary "Lion King side-eye" to the viral dance moves
                of the Serengeti, Halloween Owl curates the best of African
                internet culture with a twist of Halloween flair.
              </motion.p>
              <motion.p
                className="font-Comfortaa text-[13px]  md:text-[16px]"
                initial={{ opacity: 0, translateY: "100%" }}
                whileInView={{ opacity: 1, translateY: "0%" }}
                transition={{
                  delay: 0.1,
                  duration: 0.5,
                  type: "spring",
                  stiffness: 60,
                }}
                viewport={{ once: true, amount: 0.5 }}
              >
                Join Halloween Owl on a journey through the shadows of African
                humor, where every meme is a treat and every share is a trick to
                keep you scrolling.
              </motion.p>
              <motion.p
                className="font-Comfortaa text-[13px] md:text-[16px]"
                initial={{ opacity: 0, translateY: "100%" }}
                whileInView={{ opacity: 1, translateY: "0%" }}
                transition={{
                  delay: 0.1,
                  duration: 0.5,
                  type: "spring",
                  stiffness: 60,
                }}
                viewport={{ once: true, amount: 0.5 }}
              >
                Unleash your laughter and embrace the supernatural world of
                memes with Halloween Owl - Africa's Meme Supreme!
              </motion.p>
              <motion.p
                className="font-Comfortaa text-[13px] md:text-[16px]"
                initial={{ opacity: 0, translateY: "100%" }}
                whileInView={{ opacity: 1, translateY: "0%" }}
                transition={{
                  delay: 0.1,
                  duration: 0.5,
                  type: "spring",
                  stiffness: 60,
                }}
                viewport={{ once: true, amount: 0.5 }}
              >
                🌕👻 Happy haunting and meme-sharing! 🎃🦉
              </motion.p>

              <div className="flex gap-2  mt-5">
                {aboutIcons.map((icon, idx) => {
                  return (
                    <motion.a
                      href={icon.link}
                      target="_blank"
                      key={icon.title}
                      className=" h-12 w-12 sm:w-16 sm:h-16 bg-[#fff]  flex items-center justify-center  rounded-full"
                    >
                      <motion.img
                        initial={{ opacity: 0, translateY: "-100%" }}
                        whileInView={{ opacity: 1, translateY: "0%" }}
                        transition={{
                          delay: idx * 0.1,
                          duration: 0.5,
                          type: "spring",
                          stiffness: 70,
                        }}
                        viewport={{ once: true, amount: 0.5 }}
                        src={`/images/Hero/${icon.image}.png`}
                        alt={icon.title}
                        loading="lazy"
                        className="cursor-pointer w-10 h-10 rounded-full"
                      />
                    </motion.a>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </NewContainer>
    </Fragment>
  );
}
